import React, {useState, useEffect} from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col,
} from "reactstrap";

import helper from '../../services/helper.service';
import adminApi from '../../services/admin.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

function Login(props) {

    const [credentials, setCredentials] = useState({
        username: null,
        password: null
    });

    const login = () => {

        customerApi.login(credentials)
            .then((response) => {

            console.log("login reponse: ");
            console.log(response);
    
            if(response.status === 200){
                helper.showToast('Success', 'Successfully logged in.', 1);
                
                session.set('isLogin', true);
                const data = response.data;
                if(data.username === 'admin'){
                    session.set('isAdmin', true);
                } else {
                    session.set('isAdmin', false);
                }

                session.set('clubInfo', {
                    clubId: data.id,
                    clubName: data.club,
                    clubNameToShow: data.NameToShow,
                    clubUserName: data.username,
                    latitude: data.geoFencedLatitude,
                    longitude: data.geoFencedLongitude
                });
        
                window.location.reload();
                
    
            }else {
                session.set('isLogin', false);
                helper.showToast('Error', 'Failed to log in.', 3);
            }
        })
        .catch((error) => {
            console.log("err: ", error);
            session.set('isLogin', false);
            helper.showToast('Error', 'Invalid auth', 3);
        })
    }

    return (
        <>
             <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Sign in with credentials</small>
                        </div>
                        <Form role="form">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                        
                            <Input
                                placeholder="User name"
                          
                                autoComplete="new-email"
                                onChange={(e) => setCredentials({...credentials, username: e.target.value})}
                            />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                        
                            <Input
                                placeholder="Password"
                                type="password"
                                onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                                
                            />
                            </InputGroup>
                        </FormGroup>
                      
                        <div className="text-center">
                            <Button className="my-4" color="primary" type="button"
                            onClick={() => login()}>
                            Sign in
                            </Button>
                        </div>
                        </Form>
                    </CardBody>
                </Card>
              
            </Col>
        </>
    )
}

export default Login;