import { useState, useEffect, useRef } from "react";
// node.js library that concatenates classes (strings)

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	Table,
	Row,
	Button,
	Modal,
	Form
} from "reactstrap";

import { usePubNub } from 'pubnub-react';
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import CustomerHeader from "../../components/Headers/CustomerHeader";
import BounceLoader from "react-spinners/BounceLoader";
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';
import adminApi from '../../services/admin.service';
import { CSVLink } from "react-csv";
import './summary.scss';
global.sortMode = "byOrder";


const override:CSSProperties  = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const headers = [
	{ label: "Golfer ID", key: "golferId" },
	{ label: "Name", key: "name" },
	{ label: "Device", key: "deviceType" },
	{ label: "Last Use", key: "lastUse" },
	{ label: "Total Visits", key: "totalCount" },
	{ label: "Total Minutes", key: "totalSum" },
	{ label: "Left Visits", key: "leftCount" },
	{ label: "Left Minutes", key: "leftSum" },
	{ label: "Right Visits", key: "rightCount" },
	{ label: "Right Minutes", key: "rightSum" }
];


function  Summary(props){
	const history = useHistory();

	const [golferCount, setGolferCount] = useState(0);
	const [historyList, setHistoryList] = useState([]);
	const [totalItem, setTotalItem] = useState();
	let [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState({
        period: "30"
    });

	const [file, setFile] = useState();
	const fileReader = new FileReader();

	const { clubUserName, latitude, longitude} = session.get('clubInfo');

	const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
	const [clubName, setClubName] = useState(session.get('clubInfo').clubName);
	const [clubNameToShow, setClubNameToShow] = useState(session.get('clubInfo').clubNameToShow);

	useEffect(() => {
			
		console.log("clubId: ", clubId);
		console.log("ClubName: ", clubName);
		console.log("clubNameToShow: ", clubNameToShow);
		console.log("clubUserName: ", clubUserName);

		getClubHistory("30");

	}, []);

	const getClubHistory = (period) =>{
		setLoading(true);
		
		if(clubId == 1){
			const { state } = props.location;
			console.log("club: ", state.club);
			const club = state.club;
			setClubId(club.clubId);
			setClubName(club.clubName);
			setClubNameToShow(club.name);
			let clubNameAlt = club.clubName;
			global.isAdmin = true;

			console.log("clubNameAlt: ", clubNameAlt);
			customerApi.getHistory({club: clubNameAlt, period: period })
				.then((response) => {
					console.log("getHistory Response: ");
					console.log(response);
					setLoading(false);
					global.sortNameMode = "byUpdated";
					if(response.status === 200){
						global.clubHistory = response.data;
						setGolferCount(global.clubHistory.length);

						historyProcess(global.clubHistory);
						
			
			
					} else {
						helper.showToast("Error", "Failed to get Hole Time List", 3);
					}
				})
				.catch((error) => {
						console.log("err: ", error);
						helper.showToast("Error", "Invaid auth", 3);
				})
		} else {
			console.log("clubName: ", clubName);
			customerApi.getHistory({club: clubName, period: period })
				.then((response) => {
					console.log("getHistory Response: ");
					console.log(response);
					setLoading(false);
					global.sortNameMode = "byUpdated";
					if(response.status === 200){
						global.clubHistory = response.data;
						setGolferCount(global.clubHistory.length);
	
						historyProcess(global.clubHistory);
						
			
			
					} else {
						helper.showToast("Error", "Failed to get Hole Time List", 3);
					}
				})
				.catch((error) => {
						console.log("err: ", error);
						helper.showToast("Error", "Invaid auth", 3);
				})
		}

		
	}

	const historyProcess = (historyList) => {
		let totalCount = 0;
		let totalSum = 0;
		let totalLeftCount = 0;
		let totalLeftSum = 0;
		let totalRightCount = 0;
		let totalRightSum = 0;

		let tempArray = [];
		for(let i = 0; i < historyList.length; i++){
			let tempItem = historyList[i];

			
			let item = {
				golferId: tempItem.golfer_id,
				totalCount: tempItem.total_count,
				totalSum: tempItem.total_sum,
				leftCount: tempItem.left_count,
				leftSum: tempItem.left_sum,
				rightCount: tempItem.right_count,
				rightSum: tempItem.right_sum,
				deviceType: tempItem.device_type,
				lastUse: tempItem.last_use,
				name: tempItem.name == "" ? tempItem.golfer_id : tempItem.name,
				version: tempItem.version
			}
			tempArray.push(item);

			totalCount = totalCount + Number(item.totalCount);
			totalSum = totalSum + Number(item.totalSum);
			totalLeftCount = totalLeftCount + Number(item.leftCount);
			totalLeftSum = totalLeftSum + Number(item.leftSum);
			totalRightCount = totalRightCount + Number(item.rightCount);
			totalRightSum = totalRightSum + Number(item.rightSum);
		}
		setHistoryList(tempArray);

		let totalItem = {
			totalCount: totalCount,
			totalSum: totalSum,
			leftCount: totalLeftCount,
			leftSum: totalLeftSum,
			rightCount: totalRightCount,
			rightSum: totalRightSum
		}

		setTotalItem(totalItem);
	}

	const handleClickPeriodFilter = (period) => {
        setFilter({...filter, period: period});
        // getReportRound(global.golfer.golfer_id, period);
		getClubHistory(period);
    }

	const onSortByName = () => {
        if(global.sortNameMode == "byName"){
            global.sortNameMode = "byNameDecrease";
            sortByNameDecrease(historyList);
        } else {
            global.sortNameMode = "byName";
            sortByName(historyList);
        }
     
    }

	const sortByName = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.name; // ignore upper and lowercase
			const nameB = b.name; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByNameDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.name; // ignore upper and lowercase
			const nameB = b.name; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByLastUse = () => {
        if(global.sortNameMode == "sortByLastUse"){
            global.sortNameMode = "sortByLastUseDecrease";
            sortByLastUseDecrease(historyList);
        } else {
            global.sortNameMode = "sortByLastUse";
            sortByLastUse(historyList);
        }
     
    }

	const sortByLastUse = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.lastUse; // ignore upper and lowercase
			const nameB = b.lastUse; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByLastUseDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.lastUse; // ignore upper and lowercase
			const nameB = b.lastUse; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByVisit = () => {
        if(global.sortNameMode == "sortByVisit"){
            global.sortNameMode = "sortByVisitDecrease";
            sortByVisitDecrease(historyList);
        } else {
            global.sortNameMode = "sortByVisit";
            sortByVisit(historyList);
        }
     
    }

	const sortByVisit = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.totalCount); // ignore upper and lowercase
			const nameB = Number(b.totalCount); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByVisitDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.totalCount); // ignore upper and lowercase
			const nameB = Number(b.totalCount); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByMinute = () => {
        if(global.sortNameMode == "sortByMinute"){
            global.sortNameMode = "sortByMinuteDecrease";
            sortByMinuteDecrease(historyList);
        } else {
            global.sortNameMode = "sortByMinute";
            sortByMinute(historyList);
        }
     
    }

	const sortByMinute = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.totalSum); // ignore upper and lowercase
			const nameB = Number(b.totalSum); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByMinuteDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.totalSum); // ignore upper and lowercase
			const nameB = Number(b.totalSum); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByLeftVisit = () => {
        if(global.sortNameMode == "sortByLeftVisit"){
            global.sortNameMode = "sortByLeftVisitDecrease";
            sortByLeftVisitDecrease(historyList);
        } else {
            global.sortNameMode = "sortByLeftVisit";
            sortByLeftVisit(historyList);
        }
     
    }

	const sortByLeftVisit = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.leftCount); // ignore upper and lowercase
			const nameB = Number(b.leftCount); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByLeftVisitDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.leftCount); // ignore upper and lowercase
			const nameB = Number(b.leftCount); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByLeftMinute = () => {
        if(global.sortNameMode == "sortByLeftMinute"){
            global.sortNameMode = "sortByLeftMinuteDecrease";
            sortByLeftMinuteDecrease(historyList);
        } else {
            global.sortNameMode = "sortByLeftMinute";
            sortByLeftMinute(historyList);
        }
     
    }

	const sortByLeftMinute = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.leftSum); // ignore upper and lowercase
			const nameB = Number(b.leftSum); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByLeftMinuteDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.leftSum); // ignore upper and lowercase
			const nameB = Number(b.leftSum); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByRightVisit = () => {
        if(global.sortNameMode == "sortByRightVisit"){
            global.sortNameMode = "sortByLeftRightDecrease";
            sortByLeftRightDecrease(historyList);
        } else {
            global.sortNameMode = "sortByRightVisit";
            sortByRightVisit(historyList);
        }
     
    }

	const sortByRightVisit = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.rightCount); // ignore upper and lowercase
			const nameB = Number(b.rightCount); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByLeftRightDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.rightCount); // ignore upper and lowercase
			const nameB = Number(b.rightCount); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByRightMinute = () => {
        if(global.sortNameMode == "sortByRightMinute"){
            global.sortNameMode = "sortByRightMinuteDecrease";
            sortByRightMinuteDecrease(historyList);
        } else {
            global.sortNameMode = "sortByRightMinute";
            sortByRightMinute(historyList);
        }
     
    }

	const sortByRightMinute = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.rightSum); // ignore upper and lowercase
			const nameB = Number(b.rightSum); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByRightMinuteDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.rightSum); // ignore upper and lowercase
			const nameB = Number(b.rightSum); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onSortByVersion = () => {
        if(global.sortNameMode == "sortByVersion"){
            global.sortNameMode = "sortByVersionDecrease";
            sortByVersionDecrease(historyList);
        } else {
            global.sortNameMode = "sortByVersion";
            sortByVersion(historyList);
        }
     
    }

	const sortByVersion = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.version; // ignore upper and lowercase
			const nameB = b.version; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByVersionDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.version; // ignore upper and lowercase
			const nameB = b.version; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

	const onDetail = (e) => {
		const index = e.target.id;
		console.log("detail index: ", index);

        const selectedGolfer = historyList[index];
        console.log("selected Golfer; ", selectedGolfer);
        history.push('/customer/individualhistory', {golfer: selectedGolfer});

		if(global.isAdmin){
			console.log("club Name: ", clubName);
			history.push('/admin/clubstatus', {golfer: selectedGolfer, club: clubName, clubNameToShow: clubNameToShow});
		}else {
			history.push('/customer/individualhistory', {golfer: selectedGolfer});
		}
	}

	const handleOnChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();

		if (file) {
			fileReader.onload = function (event) {
				const csvOutput = event.target.result;
				// console.log(csvOutput);
				csvFileToArray(csvOutput);
			};

			fileReader.readAsText(file);
			
		}
	};

	const csvFileToArray = string => {
		const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
		const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
		console.log("header: ", csvHeader);
		let myHeader = ["number", "date", "club_id", "club", "golfer_id", "visit_time", "elapsed_time", "left_elapsed_time", "right_elapsed_time", "opt1", "opt1_alt",  "opt2", "version", "background_at", "background_mode", "created_at", "finished", "alter"];
		const array = csvRows.map(i => {
			const values = i.split(",");
			const obj = myHeader.reduce((object, header, index) => {
				object[header] = values[index];
				return object;
			}, {});
			return obj;
		});
		console.log(array);
		var tempArray = [];

		console.log("edit: ")

		array.forEach(item => {
			var repStr = ""
			if(item.opt1){
				repStr = item.opt1.slice(1);
			}

			var alt = ""
			if(item.opt1_alt) {
				alt = item.opt1_alt.slice(0,  item.opt1_alt.length-1);
			}

			var visit_converted_time = "";
			if(item.date) {
				visit_converted_time = Moment(item.date).format('YYYY/MM/DD');
			}

			var background_time = "";
			if(item.background_at) {
				background_time = Moment(item.background_at).format('YYYY/MM/DD HH:mm');
			}

			var created_time = "";
			if(item.created_at) {
				created_time = Moment(item.created_at).format('YYYY/MM/DD HH:mm');
			}
			
			const new_item = {
				number: item.number,
				date: visit_converted_time,
				club_id: Number(item.club_id),
				club: item.club,
				golfer_id: item.golfer_id == undefined ? "" : item.golfer_id,
				visit_time: item.visit_time,
				elapsed_time: Number(item.elapsed_time),
				left_elapsed_time:item.left_elapsed_time != undefined ?  Number(item.left_elapsed_time) : 0,
				right_elapsed_time: item.right_elapsed_time != undefined ? Number(item.right_elapsed_time) : 0,
				opt1: repStr + ", " + alt,
				version: item.version,
				background_at: background_time,
				background_mode: item.background_mode,
				created_at: created_time,
				finished: item.finished == "TRUE" ? "true" : "false"
			}
			tempArray.push(new_item)
		});

		console.log(tempArray);

		const requestItem = {
			data: tempArray
		}

		customerApi.updateBoxWithCSV(requestItem)
			.then((response) => {

			console.log("box reponse: ");
			console.log(response);
	
			if(response.status === 200){
				helper.showToast('Success', 'Successfully Updated.', 1);
				
			
	
			}else {
			
				helper.showToast('Error', 'Failed to update box.', 3);
			}
		})
		.catch((error) => {
			console.log("err: ", error);
	
			helper.showToast('Error', 'Invalid club', 3);
		})

		
	}

	return (
		<>
	
			<CustomerHeader />
			<Card className="bg-white shadow mt--6 mb-5">

				<CardHeader className="bg-transparent border-0">
					<BounceLoader color="#36d7b7" loading={loading} cssOverride={override} size={40} />
					<Row className="align-items-center">
						<div className="col">
							<h3 className="text-info mb-0 small">Total Users: {golferCount}</h3>
						</div>
						<div className="col">
							<h2 className="text-black mb-0 text-center ">{clubNameToShow}</h2>
						</div>
						<div className="col text-right">
							<Button
								color={filter.period === "30" ? "success" : "info"}
						
								onClick={(e) => handleClickPeriodFilter("30")}
							
								size="sm"
								// id={index}
							>
							
								30 Days
							</Button>
							<Button
								color={filter.period === "90" ? "success" : "info"}
						
								onClick={(e) => handleClickPeriodFilter("90")}
							
								size="sm"
								// id={index}
							>
							
								90 Days
							</Button>
							<Button
								color={filter.period === "365" ? "success" : "info"}
						
								onClick={(e) => handleClickPeriodFilter("365")}
							
								size="sm"
								// id={index}
							>
							
								Cal Year
							</Button>
							{/* <Button 
								size="sm"
								color="white"
								// onClick={(e) => exportCSV()}
							>
								<CSVLink data={historyList} headers={headers}>
									Export CSV
								</CSVLink>
							</Button>

							<Form>
										<input
											type={"file"}
											id={"csvFileInput"}
											accept={".csv"}
											onChange={handleOnChange}
										/>

										<button
											onClick={(e) => {
												handleOnSubmit(e);
											}}
										>
											IMPORT CSV
										</button>
									</Form>
							 */}
						</div>
					
					</Row>

				</CardHeader>
				<CardBody>
					<Table
						id="table-dashboard-user"
						className={`table-gray`}
						responsive
					>
					
						<table className="table table-bordered table-hover text-center" style={{borderColor: "black"}}>
							<thead style={{color: "black", borderColor: "black", fontSize: 18}}>
								<tr>
									{/* <th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}></th> */}
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}} colSpan={11}>Past {filter.period} Days</th>
								</tr>
								<tr style={{fontSize: 14, borderColor: "black", fontWeight: "normal", borderLeftWidth: 1}}>
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}} colSpan={4}> Golfer</th>

									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold", borderLeftWidth: 1}} colSpan={2}>Total</th>
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}} colSpan={2}>Left Switch</th>
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}} colSpan={2}>Right Switch</th>
								</tr>
								<tr>
									<th
										onClick = {() => onSortByName()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Golfer ID<i className="fas fa-sort"></i>
										</div>
									</th>
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}>Device</th>
									<th 
										onClick = {() => onSortByVersion()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>	
										<div className="text-black mb-0 text-center">
											App Version<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByLastUse()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>	
										<div className="text-black mb-0 text-center">
											Last Use<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByVisit()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Visits<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByMinute()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Minutes<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByLeftVisit()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Visits<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByLeftMinute()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Minutes<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByRightVisit()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Visits<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByRightMinute()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Minutes<i className="fas fa-sort"></i>
										</div>
									</th>
									<th 
										onClick = {() => onSortByRightMinute()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Action
										</div>
									</th>
								</tr>
							</thead>
							<tbody style={{color: "black"}}>
								{
									historyList.length > 0 ?
										historyList.map((item, index) => 
										<tr key = {index}>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{ item.name == "" ? item.golferId : item.name}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.deviceType}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.version}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.lastUse}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.totalCount}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.totalSum}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.leftCount}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.leftSum}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.rightCount}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.rightSum}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>
												<Button
													color="success"
													size="sm"
													id={index}
													onClick={(e) => onDetail(e)}
												>
													Detail
												</Button>
											</td>
										</tr>
										)
										:
										<></>
								}
							
					
							</tbody>
							<tfoot style={{color: "black"}}>
								{
									totalItem ? 
									<tr>
										<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}  colSpan={4}>Grand Total</th>
										<th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.totalCount}</th>
										<th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.totalSum}</th>
										<th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.leftCount}</th>
										<th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.leftSum}</th>
										<th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.rightCount}</th>
										<th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.rightSum}</th>
									</tr>
									:
									<></>

								}
						
							</tfoot>
						</table>

						
					</Table>
				</CardBody>
			</Card>
		</>
	);
};

export default Summary;