import React, {useState, useEffect} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from '../layouts/Auth.js';
import AdminLayout from "../layouts/Admin.js";
import CustomerLayout from '../layouts/Customer';

import session from '../services/session.service';
import { useHistory } from "react-router-dom";

import  { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";

Amplify.configure(awsmobile);

function App(props){

    const [isLogin, setIsLogin] = useState(session.get('isLogin') || false);
    const [isAdmin, setIsAdmin] = useState(session.get('isAdmin') || false);

    useEffect(() => {
        setIsLogin(session.get('isLogin') || false);
        setIsAdmin(session.get('isAdmin'));
        
        console.log("isLogin: ", isLogin);
        console.log("isAdmin: ", isAdmin);
    }, [isLogin, isAdmin]);
    return(

        <Switch>
    
        {
            !isLogin ? 
                <Route path="/" render={(props) => <AuthLayout {...props} />} />
                :
                !isAdmin ?
                    <Route path="/" render={(props) => <CustomerLayout {...props} />} />
                    :
                    <Route path="/" render={(props) => <AdminLayout {...props} />} />

        }
      
      </Switch>

  
    )
}

export default App;