import http from "./http.service";
import session from "./session.service";

const path = {
    login: 'customer/loginClub',
    getHistory: 'customer/getHistory',
    getNameHistory: 'customer/getNameList',
    updateGolferList: 'customer/updateGolferList',
    deleteGolfer: 'customer/deleteGolfer',
    getReportSummary: 'customer/getIndividualHistory',
    updateBoxWithCSV: 'customer/recoverDB',
  
}

export default {
    login: function(credential) {
        return http.post(path.login, credential, {});
    },
    getHistory: function(body){
        return http.post(path.getHistory, body, {});
    },
    getNameHistory: function(body){
        return http.post(path.getNameHistory, body, {});
    },
    getReportSummary: function(body){
        return http.post(path.getReportSummary, body, {});
    },
    updateGolferList: function(body){
        return http.post(path.updateGolferList, body, {});
    },
    deleteGolfer: function(body){
        return http.post(path.deleteGolfer, body, {});
    },
    updateBoxWithCSV: function(body){
        return http.post(path.updateBoxWithCSV, body, {});
    }
}