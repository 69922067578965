/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:41ee77c6-2b5b-4118-9f10-c2b6ed7786c5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OQJXHT7WL",
    "aws_user_pools_web_client_id": "6glv2kaljcpjkrvokpfjrr78mc",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "permissions",
            "endpoint": "https://f4r84m03h1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "accurange",
            "endpoint": "https://e29z0ivdcc.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "geoFencingAPI",
            "endpoint": "https://drq1vf4dmh.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "accupin-app-storage212237-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
