import React, {useState, useEffect, CSSProperties } from 'react';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    Nav,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

import { usePubNub } from 'pubnub-react';
import Chart from "react-apexcharts";

import CustomerHeader from "../../components/Headers/CustomerHeader";
import { useHistory } from 'react-router-dom';
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';
import { CSVLink } from "react-csv";
import BounceLoader from "react-spinners/BounceLoader";

const headers = [
	
	{ label: "Device", key: "deviceType" },
	{ label: "Date", key: "lastUse" },
	{ label: "Total Minutes", key: "totalSum" },
	{ label: "Left Minutes", key: "leftSum" },
	{ label: "Right Minutes", key: "rightSum" }
];
global.sortMode = "sortByLastUse";

const override:CSSProperties  = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

function IndividualHistory(props){
    const history = useHistory();
	const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
	const [clubName, setClubName] = useState(session.get('clubInfo').clubName);
	const [clubNameToShow, setClubNameToShow] = useState(session.get('clubInfo').clubNameToShow);
    const [historyList, setHistoryList] = useState([]);
	const [historyCount, setHistoryCount] = useState(0);
    const { state } = props.location;
    const golfer = state.golfer;
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    const [totalItem, setTotalItem] = useState();

    const [isRead, setIsRead] = useState(false);

    const [filter, setFilter] = useState({
        period: 30,
        holeNum: 1,
        checkHalf: "front" // "back", "turn"
    });

    useEffect(() => {
        const { state } = props.location;
        const golfer = state.golfer;
        console.log("golfer: ", golfer);
        const golferId = golfer.golferId;
        console.log("golfer Id: ", golferId);
        if(!isRead){
            setIsRead(true);
            getReportSummary(30);
        }
    }, [])

    const getReportSummary = (period) => {
        setLoading(true);

		if(clubId == 1){
			const { state } = props.location;
			console.log("club: ", state.club);
			const club = state.club;
		
			setClubName(club);
			setClubNameToShow(state.clubNameToShow);
			let clubNameAlt = club;
			global.isAdmin = true;
			console.log("clubNameAlt: ", clubNameAlt);
			customerApi.getReportSummary({club: clubNameAlt, golfer_id: golfer.golferId, period: period})
            .then((response) => {
                console.log("getReportSummary Response: ");
                console.log(response);
                setLoading(false);

                let temphistoryList = response.data;
                setHistoryCount(temphistoryList.length);

                if(response.status === 200){

                    historyProcess(temphistoryList);
                }
            })
		} else {
			customerApi.getReportSummary({club: clubName, golfer_id: golfer.golferId, period: period})
            .then((response) => {
                console.log("getReportSummary Response: ");
                console.log(response);
                setLoading(false);

                let temphistoryList = response.data;
                setHistoryCount(temphistoryList.length);

                if(response.status === 200){

                    historyProcess(temphistoryList);
                }
            })
		}
       
    }

    const historyProcess = (historyList) => {
	
		let totalSum = 0;
		let totalLeftSum = 0;
		let totalRightSum = 0;
		let tempArray = [];

		for(let i = 0; i < historyList.length; i++){
			let tempItem = historyList[i];

			let item = {
				golferId: tempItem.golfer_id,
                totalSum: tempItem.elapsed_time,
				leftSum: tempItem.left_elapsed_time,
				rightSum: tempItem.right_elapsed_time,
				deviceType: tempItem.opt1,
				lastUse: tempItem.date + " " + tempItem.visit_time,
				version: tempItem.version ? tempItem.version : "5.3.2"
			}

            console.log("item: ", item);

			tempArray.push(item);

			totalSum = totalSum + Number(item.totalSum);
			totalLeftSum = totalLeftSum + Number(item.leftSum);
			totalRightSum = totalRightSum + Number(item.rightSum);
		}
		sortByLastUseDecrease(tempArray);

		let totalItem = {
			totalSum: totalSum,
			leftSum: totalLeftSum,
			rightSum: totalRightSum
		}

		setTotalItem(totalItem);
	}

    const handleClickPeriodFilter = (period) => {
        
        if(!loading){
            setFilter({...filter, period: period});
            getReportSummary(period);
        }
    }

    const onSortByLastUse = () => {
        if(global.sortNameMode == "sortByLastUse"){
            global.sortNameMode = "sortByLastUseDecrease";
            sortByLastUseDecrease(historyList);
        } else {
            global.sortNameMode = "sortByLastUse";
            sortByLastUse(historyList);
        }
     
    }

	const sortByLastUse = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.lastUse; // ignore upper and lowercase
			const nameB = b.lastUse; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByLastUseDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.lastUse; // ignore upper and lowercase
			const nameB = b.lastUse; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const onSortByMinute = () => {
        if(global.sortNameMode == "sortByMinute"){
            global.sortNameMode = "sortByMinuteDecrease";
            sortByMinuteDecrease(historyList);
        } else {
            global.sortNameMode = "sortByMinute";
            sortByMinute(historyList);
        }
     
    }

	const sortByMinute = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.totalSum); // ignore upper and lowercase
			const nameB = Number(b.totalSum); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByMinuteDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.totalSum); // ignore upper and lowercase
			const nameB = Number(b.totalSum); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const onSortByLeftMinute = () => {
        if(global.sortNameMode == "sortByLeftMinute"){
            global.sortNameMode = "sortByLeftMinuteDecrease";
            sortByLeftMinuteDecrease(historyList);
        } else {
            global.sortNameMode = "sortByLeftMinute";
            sortByLeftMinute(historyList);
        }
     
    }

	const sortByLeftMinute = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.leftSum); // ignore upper and lowercase
			const nameB = Number(b.leftSum); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByLeftMinuteDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.leftSum); // ignore upper and lowercase
			const nameB = Number(b.leftSum); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const onSortByRightMinute = () => {
        if(global.sortNameMode == "sortByRightMinute"){
            global.sortNameMode = "sortByRightMinuteDecrease";
            sortByRightMinuteDecrease(historyList);
        } else {
            global.sortNameMode = "sortByRightMinute";
            sortByRightMinute(historyList);
        }
     
    }

	const sortByRightMinute = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.rightSum); // ignore upper and lowercase
			const nameB = Number(b.rightSum); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    const sortByRightMinuteDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = Number(a.rightSum); // ignore upper and lowercase
			const nameB = Number(b.rightSum); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setHistoryList(tempArray);
    }

    return (
        <>
            <CustomerHeader/>
            
            <Card className="bg-white shadow mt--6 mb-5">

				<CardHeader className="bg-transparent border-0">
					<BounceLoader color="#36d7b7" loading={loading} cssOverride={override} size={40} />
					<Row className="align-items-center">
						<div className="col">
							<h3 className="text-info mb-0 small">Golfer ID: {golfer ? golfer.golferId : ""}</h3>
                            <h3 className="text-info mb-0 small">Golfer Name: {golfer ? golfer.name : ""}</h3>
						</div>
						<div className="col">
							<h2 className="text-black mb-0 text-center ">{clubNameToShow}</h2>
						</div>
						<div className="col text-right">
							<Button
								color={filter.period === "30" ? "success" : "info"}
						
								onClick={(e) => handleClickPeriodFilter("30")}
							
								size="sm"
								// id={index}
							>
							
								30 Days
							</Button>
							<Button
								color={filter.period === "90" ? "success" : "info"}
						
								onClick={(e) => handleClickPeriodFilter("90")}
							
								size="sm"
								// id={index}
							>
							
								90 Days
							</Button>
							<Button
								color={filter.period === "365" ? "success" : "info"}
						
								onClick={(e) => handleClickPeriodFilter("365")}
							
								size="sm"
								// id={index}
							>
							
								Cal Year
							</Button>
							<Button 
								size="sm"
								color="white"
								// onClick={(e) => exportCSV()}
							>
								<CSVLink data={historyList} headers={headers}>
									Export CSV
								</CSVLink>
							</Button>
							
						</div>
					
					</Row>

				</CardHeader>
				<CardBody>
					<Table
						id="table-dashboard-user"
						className={`table-gray`}
						responsive
					>
					
						<table className="table table-bordered table-hover text-center" style={{borderColor: "black"}}>
							<thead style={{color: "black", borderColor: "black", fontSize: 18}}>
								<tr>
									{/* <th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}></th> */}
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}} colSpan={10}>Past {filter.period} Days</th>
								</tr>
							
								<tr>
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}>Device</th>
									<th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}>Version</th>
									<th 
										onClick = {() => onSortByLastUse()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>	
										<div className="text-black mb-0 text-center">
											Date<i className="fas fa-sort"></i>
										</div>
									</th>
								
									<th 
										onClick = {() => onSortByMinute()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Total Minutes<i className="fas fa-sort"></i>
										</div>
									</th>
									
									<th 
										onClick = {() => onSortByLeftMinute()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Left Switch Minutes<i className="fas fa-sort"></i>
										</div>
									</th>
								
									<th 
										onClick = {() => onSortByRightMinute()}
										style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}
									>
										<div className="text-black mb-0 text-center">
											Right Switch Minutes<i className="fas fa-sort"></i>
										</div>
									</th>
								
								</tr>
							</thead>
							<tbody style={{color: "black"}}>
								{
									historyList.length > 0 ?
										historyList.map((item, index) => 
										<tr key = {index}>
											{/* <td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{ item.name == "" ? item.golferId : item.name}</td> */}
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.deviceType}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.version}</td>
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.lastUse}</td>
											
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.totalSum}</td>
											
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.leftSum}</td>
											
											<td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.rightSum}</td>
										
										</tr>
										)
										:
										<></>
								}
							
					
							</tbody>
							<tfoot style={{color: "black"}}>
							{
                                totalItem ? 
                                <tr>
                                    <th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}  colSpan={3}>Grand Total</th>
                               
                                    <th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.totalSum}</th>
                            
                                    <th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.leftSum}</th>
                 
                                    <th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{totalItem.rightSum}</th>
                                </tr>
                                :
                                <></>

                            }
						
							</tfoot>
						</table>

						
					</Table>
				</CardBody>
			</Card>
        </>
    )
}

export default IndividualHistory;