import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)


// reactstrap components
import {
  Card,
  CardHeader,
  Input,
  Table,
  Modal,
  Row,
  Col,
  Button
} from "reactstrap";

import CustomerHeader from "../../components/Headers/CustomerHeader";

import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

function  SetTime(){

  const [isRead, setIsRead] = useState(false);
  
  const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
  const [clubName, setClubName] = useState(session.get('clubInfo').clubName);
  const [existTurn, setExistTurn] = useState(session.get('clubInfo').existTurn);

  const [boxTimeList, setBoxTimeList] = useState([]);
  const [frontTimeList, setFrontTimeList] = useState([]);
  const [backTimeList, setBackTimeList] = useState([]);

  const [isFrontEdit, setIsFrontEdit] = useState(false);
  const [isBackEdit, setIsBackEdit] = useState(false);

  const [frontParTotal, setFrontParTotal] = useState(0);
  const [frontTimeTotal, setFrontTimeTotal] = useState(0);

  const [backParTotal, setBackParTotal] = useState(0);
  const [backTimeTotal, setBackTimeTotal] = useState(0);

  const [turnTime, setTurnTime] = useState(0);

  const [check10m, setCheck10m] = useState(false);
  const [check5m, setCheck5m] = useState(false);
  const [check0, setCheck0] = useState(true);
  const [check5, setCheck5] = useState(false);
  const [check10, setCheck10] = useState(false);

  const [alertShow, setAlertShow] = useState(false);

  useEffect(() => {
       
    console.log("clubId: ", clubId);
    console.log("ClubName: ", clubName);

    if(!isRead){
        setAlertShow(true);
        setIsRead(true);
        getBoxTimeList(clubId);
        getGolferList(clubId);
    }
   
  })

  const getGolferList = (clubId) => {
		customerApi.getGolferList({club_id: clubId})
			.then((response) => {
				console.log("getGolferList Response: ");
				console.log(response);

				if(response.status === 200){

				let tempGolferList = response.data;
				// setGolferList(tempGolferList);
			
        global.golferCount = tempGolferList.length;

        if(global.golferCount > 0){
          setAlertShow(true);
        }

		
		
				} else {
					helper.showToast("Error", "Failed to get Hole Time List", 3);
				}
			})
			.catch((error) => {
					console.log("err: ", error);
					helper.showToast("Error", "Invaid auth", 3);
			})
	}

  const getBoxTimeList = (clubId) => {
    customerApi.getBoxTimeList({club_id: clubId})
      .then((response) => {
        console.log("HoleTimeList Response: ");
        console.log(response);

        if(response.status === 200){
          let tempBoxTimeList = response.data;
          let tempFrontParTotal = 0;
          let tempFrontTimeTotal = 0;
          let tempBackParTotal = 0;
          let tempBackTimeTotal = 0;

          const standardList = getStandardBoxTimeList(tempBoxTimeList);

          for(let i = 0; i < standardList.length; i++){
            if(i < 9){
              tempFrontParTotal += Number(standardList[i].par);
              tempFrontTimeTotal += Number(standardList[i].box_time);
            }else if(i > 9 && i < 19){
              tempBackParTotal += Number(standardList[i].par);
              tempBackTimeTotal += Number(standardList[i].box_time);
            }
          }

          setCheckBox(tempBoxTimeList[19].box_time);
          setFrontParTotal(tempFrontParTotal);
          setFrontTimeTotal(tempFrontTimeTotal);
          setBackParTotal(tempBackParTotal);
          setBackTimeTotal(tempBackTimeTotal);

          if(existTurn){
            setTurnTime(Number(standardList[9].box_time));
          }else {
            setTurnTime(0);
          }
          
          setBoxTimeList(standardList);
        } else {
          helper.showToast("Error", "Failed to get Hole Time List", 3);
        }
      })
      .catch((error) => {
        console.log("err: ", error);
        helper.showToast("Error", "Invaid auth", 3);
      })
  }

  const getStandardBoxTimeList = tempList => {
    let multiplier = 1;
    let value = Number(tempList[19].box_time);
    switch (value) {
      case 9:
        multiplier = 1 / 0.9;
        break;
      case 9.5:
        multiplier = 1 / 0.95;
        break;
      case 10:
        multiplier = 1;
        break;
      case 10.5:
        multiplier = 1 / 1.05;
        break;
      case 11:
        multiplier = 1 / 1.1;
        break;
      default:
          multiplier = 1;
    }

    let standardList = [];

    for(let i = 0; i < tempList.length; i++){
      let item = tempList[i];

      let tempItem = {
        id: item.id,
        box_number: item.box_number,
        par: item.par,
        box_time: Math.round((item.box_time * multiplier) * 100) / 100,
        desc: item.desc
      };

      standardList.push(tempItem);
    }

    // setBoxTimeList(standardList);
    return standardList;
  }

  const setCheckBox = (value) => {
  
    const checkValue = Number(value);
    console.log(checkValue);
    switch (checkValue) {
      case 9:
        setCheck10m(true);
        setCheck5m(false);
        setCheck0(false);
        setCheck5(false);
        setCheck10(false);
        return;
      case 9.5:
        setCheck10m(false);
        setCheck5m(true);
        setCheck0(false);
        setCheck5(false);
        setCheck10(false);
        return;
      case 10:
        setCheck10m(false);
        setCheck5m(false);
        setCheck0(true);
        setCheck5(false);
        setCheck10(false);
        return;
      case 10.5:
        setCheck10m(false);
        setCheck5m(false);
        setCheck0(false);
        setCheck5(true);
        setCheck10(false);
        return;
      case 11:
        setCheck10m(false);
        setCheck5m(false);
        setCheck0(false);
        setCheck5(false);
        setCheck10(true);
        return;
      default:
        setCheck10m(false);
        setCheck5m(false);
        setCheck0(true);
        setCheck5(false);
        setCheck10(false);
    }
  }

  const onEdit = (e) => {
    console.log("id: ", e.target.id);

    if(e.target.id == 'front'){
      let tempFrontTimeList = [];
      let tempFrontParTotal = 0;
      let tempFrontTimeTotal = 0;

      for(let i = 0; i < 10; i++){
        let item = boxTimeList[i];
        let par = item.par;
        if(i == 9){
          par = 0;
        }
        let tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: par,
          box_time: item.box_time,
          desc: item.desc
        };

        if(i < 9){
          tempFrontParTotal += Number(tempItem.par);
          tempFrontTimeTotal += Number(tempItem.box_time);
        }
        tempFrontTimeList.push(tempItem);

      }

      setFrontTimeList(tempFrontTimeList);
      setFrontParTotal(tempFrontParTotal);
      setFrontTimeTotal(tempFrontTimeTotal);
      setIsFrontEdit(true);
    }else {
      let tempBackTimeList = [];
      let tempBackParTotal = 0;
      let tempBackTimeTotal = 0;

      for(let i = 10; i < 19; i++){
        let item = boxTimeList[i];
        let tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: item.par,
          box_time: item.box_time,
          desc: item.desc
        };

        tempBackParTotal += Number(tempItem.par);
        tempBackTimeTotal += Number(tempItem.box_time);
        tempBackTimeList.push(tempItem);
      }

      setBackTimeList(tempBackTimeList);
      setBackParTotal(tempBackParTotal);
      setBackTimeTotal(tempBackTimeTotal);
      setIsBackEdit(true);
    }
  }

  const onFrontSelect = e => {
    const index = e.target.id;
    const value = Number(e.target.value);

    console.log("index: ", index);
    console.log("value: ", value);

    let tempFrontTimeList = [];
    let tempFrontParTotal = 0;
    let tempFrontTimeTotal = 0;

    for(let i = 0; i < 10; i++){
      let item = frontTimeList[i];
      let tempItem;

      if(i == index){
        tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: value,
          box_time: value == 3 ? 10.0 : value == 4 ? 13.0 : 16.0,
          desc: item.desc
        };
      } else {
        tempItem = item
      }
      if(i < 9){
        tempFrontParTotal += Number(tempItem.par);
        tempFrontTimeTotal += Number(tempItem.box_time);
      }
      
      tempFrontTimeList.push(tempItem);
    }

    console.log("tempFrontTimeList: ", tempFrontTimeList);

    setFrontTimeList(tempFrontTimeList);
    setFrontParTotal(tempFrontParTotal);
    setFrontTimeTotal(tempFrontTimeTotal);

    if(existTurn){
      setTurnTime(Number(tempFrontTimeList[9].box_time));
    }else {
      setTurnTime(0);
    }
  }

  const onBackSelect = e => {
    const index = e.target.id;
    const value = Number(e.target.value);

    console.log("index: ", index);
    console.log("value: ", value);

    let tempBackTimeList = [];
    let tempBackParTotal = 0;
    let tempBackTimeTotal = 0;

    for(let i = 0; i < backTimeList.length; i++){
      let item = backTimeList[i];
      let tempItem;

      if(i == index){
        tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: value,
          box_time: value == 3 ? 10.0 : value == 4 ? 13.0 : 16.0,
          desc: item.desc
        };
      } else {
        tempItem = item
      }
    
      tempBackParTotal += Number(tempItem.par);
      tempBackTimeTotal += Number(tempItem.box_time);

      tempBackTimeList.push(tempItem);
    }

    console.log("tempBackTimeList: ", tempBackTimeList);

    setBackTimeList(tempBackTimeList);
    setBackParTotal(tempBackParTotal);
    setBackTimeTotal(tempBackTimeTotal);
  }

  const getCheckValue = value => {
    console.log("value: ", value);
    console.log("check box: ", check10m);
    if(check10m) {
      return value * 0.9;
    }
    if(check5m) {
      return value * 0.95;
    }
    if(check0) {
      return value;
    }
    if(check5) {
      return value * 1.05;
    }
    if(check10) {
      return value * 1.1;
    }

  }

  const onFrontChange = e => {
    const index = e.target.id;
    const value = Number(e.target.value);

    console.log("index: ", index);
    console.log("value: ", value);

    let tempFrontTimeList = [];
    let tempFrontParTotal = 0;
    let tempFrontTimeTotal = 0;

    for(let i = 0; i < 10; i++){
      let item = frontTimeList[i];
      let tempItem;

      if(i == index){
        tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: item.par,
          box_time: value,
          desc: item.desc
        };
      } else {
        tempItem = item
      }

      if(i < 9){
        tempFrontParTotal += Number(tempItem.par);
        tempFrontTimeTotal += Number(tempItem.box_time);
      }

      tempFrontTimeList.push(tempItem);
    }

    console.log("tempFrontTimeList: ", tempFrontTimeList);

    setFrontTimeList(tempFrontTimeList);
    setFrontParTotal(tempFrontParTotal);
    setFrontTimeTotal(tempFrontTimeTotal);

    if(existTurn){
      setTurnTime(Number(tempFrontTimeList[9].box_time));
    }else {
      setTurnTime(0);
    }
   
  }

  const onBackChange = e => {
    const index = e.target.id;
    const value = Number(e.target.value);

    console.log("index: ", index);
    console.log("value: ", value);

    let tempBackTimeList = [];
    let tempBackParTotal = 0;
    let tempBackTimeTotal = 0;

    for(let i = 0; i < backTimeList.length; i++){
      let item = backTimeList[i];
      let tempItem;

      if(i == index){
        tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: item.par,
          box_time: value,
          desc: item.desc
        };
      } else {
        tempItem = item
      }

      tempBackParTotal += Number(tempItem.par);
      tempBackTimeTotal += Number(tempItem.box_time);

      tempBackTimeList.push(tempItem);
    }

    console.log("tempBackTimeList: ", tempBackTimeList);

    setBackTimeList(tempBackTimeList);
    setBackParTotal(tempBackParTotal);
    setBackTimeTotal(tempBackTimeTotal);
  }

  const onFrontCancel = () => {
    setIsFrontEdit(false);
    let tempBoxTimeList = boxTimeList;
    let tempFrontParTotal = 0;
    let tempFrontTimeTotal = 0;
    for(let i = 0; i < tempBoxTimeList.length; i++){
      if(i < 9){
        tempFrontParTotal += Number(tempBoxTimeList[i].par);
        tempFrontTimeTotal += Number(tempBoxTimeList[i].box_time);
      }
    }
    setFrontParTotal(tempFrontParTotal);
    setFrontTimeTotal(tempFrontTimeTotal);
  
  }

  const onBackCancel = () => {
    setIsBackEdit(false);
    let tempBoxTimeList = boxTimeList;
    let tempBackParTotal = 0;
    let tempBackTimeTotal = 0;
    for(let i = 10; i < tempBoxTimeList.length; i++){
      if(i < tempBoxTimeList.length -1){
        tempBackParTotal += Number(tempBoxTimeList[i].par);
        tempBackTimeTotal += Number(tempBoxTimeList[i].box_time);
      }
    }
    setBackParTotal(tempBackParTotal);
    setBackTimeTotal(tempBackTimeTotal);
  
  }

  const onUpdateBoxTimeList = boxList => {

    let saveBoxList = [];
    for(let i = 0; i < boxList.length; i++){
      let item = boxList[i];

      let  tempItem = {
        id: item.id,
        box_number: item.box_number,
        par: item.par,
        box_time: getCheckValue(item.box_time),
        desc: item.desc
      };

      saveBoxList.push(tempItem);
    }

    customerApi.updateBoxTimeList({club_id: clubId, data: saveBoxList})
      .then((response) => {
        console.log("updateBoxTimeList Response: ");
        console.log(response);

        if(response.status === 200){
          setIsRead(false);
          window.location.reload();
        } else {
          helper.showToast("Error", "Failed to update Time List", 3);
        }
      })
      .catch((error) => {
        console.log("err: ", error);
        helper.showToast("Error", "Invaid auth", 3);
      })
  }

  const onFrontSave = () => {
    onUpdateBoxTimeList(frontTimeList);
  }

  const onBackSave = () => {
    onUpdateBoxTimeList(backTimeList);
  }

  const onUpdateCheckBoxList = type => {
    let multiplier = 1;
    switch (type) {
      case 9:
        multiplier = 0.9;
        break;
      case 9.5:
        multiplier = 0.95;
        break;
      case 10:
        multiplier = 1;
        break;
      case 10.5:
        multiplier = 1.05;
        break;
      case 11:
        multiplier = 1.1;
        break;
      default:
        multiplier = 1;
    }

    let saveBoxList = [];

    for(let i = 0; i < boxTimeList.length; i++){
      let item = boxTimeList[i];
      let  tempItem;
      if(i == 19){
        tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: item.par,
          box_time: type,
          desc: item.desc
        };
      } else {
        tempItem = {
          id: item.id,
          box_number: item.box_number,
          par: item.par,
          box_time: item.box_time * multiplier,
          desc: item.desc
        };
      }
      

      saveBoxList.push(tempItem);
    }
    
    console.log(saveBoxList);

    customerApi.updateBoxTimeList({club_id: clubId, data: saveBoxList})
      .then((response) => {
        console.log("updateBoxTimeList Response: ");
        console.log(response);

        if(response.status === 200){
          // setIsRead(false);
          // window.location.reload();
        } else {
          helper.showToast("Error", "Failed to update Time List", 3);
        }
      })
      .catch((error) => {
        console.log("err: ", error);
        helper.showToast("Error", "Invaid auth", 3);
      })

  }

  function handleChange(event) {

    if(isBackEdit || isFrontEdit){
      return ;
    }

    // if(global.golferCount > 0){
    //   setAlertShow(true);
    //   // return;
    // } 
      var value = event.target.value;
      console.log("You selected " + value);
      console.log("handleChange");
  
      switch (value) {
        case 'checkbox-10':
          setCheck10m(true);
          setCheck5m(false);
          setCheck0(false);
          setCheck5(false);
          setCheck10(false);
          onUpdateCheckBoxList(9);
          return;
        case 'checkbox-5':
          setCheck10m(false);
          setCheck5m(true);
          setCheck0(false);
          setCheck5(false);
          setCheck10(false);
          onUpdateCheckBoxList(9.5);
          return;
        case 'checkbox 0':
          setCheck10m(false);
          setCheck5m(false);
          setCheck0(true);
          setCheck5(false);
          setCheck10(false);
          onUpdateCheckBoxList(10);
          return;
        case 'checkbox 5':
          setCheck10m(false);
          setCheck5m(false);
          setCheck0(false);
          setCheck5(true);
          setCheck10(false);
          onUpdateCheckBoxList(10.5);
          return;
        case 'checkbox 10':
          setCheck10m(false);
          setCheck5m(false);
          setCheck0(false);
          setCheck5(false);
          setCheck10(true);
          onUpdateCheckBoxList(11);
          return;
        default:
          setCheck10m(false);
          setCheck5m(false);
          setCheck0(true);
          setCheck5(false);
          setCheck10(false);
      }
    

   
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}


        {/* <Container className="mt--8" fluid > */}
         <Row  className="mt--8">
         <Col className="mb-5 mb-xl-0" xl="6">
         <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={alertShow}
              toggle={() => setAlertShow(false)}
            >
              <div className="modal-header">
                {/* <h6 className="modal-title" id="modal-title-notification">
                  Your attention is required
                </h6> */}
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setAlertShow(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-2">ATTENTION!</h4>
                  <div className="text-white">
                  Do NOT Change Anything on this Page when Golfers are on the Course.
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Ok, Got it
                </Button> */}
                <Button
                  className="btn-white" color="default" type="button"
        
                  data-dismiss="modal"
               
                  onClick={() => setAlertShow(false)}
                >
                 Ok, Got it
                </Button>
              </div>
            </Modal>
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                
                <Row className="align-items-center">
                    <div className="col">
                        <h3 className="text-info mb-0"></h3>
                    </div>

                    <div className="col">
                        <h2 className="text-white mb-0 text-center">Front 9</h2>
                    </div>


                  
                    <div className="col text-right">

                      {
                        isFrontEdit ?
                          <div>
                            <Button
                                color="info"
                        
                                onClick={() => onFrontSave()}
                            
                                size="sm"
                            >
                              
                                Save
                            </Button>
                            <Button
                                color="warning"
                           
                                onClick={() => onFrontCancel()}
                            
                                size="sm"
                              >
                              
                                Cancel
                              </Button>
                        
                          </div>
                    
                     
                        :

                          <Button
                            color="info"
                            id={"front"}
                            onClick={(e) => onEdit(e)}
                        
                            size="sm"
                          >
                          
                            Edit
                          </Button>

                      }
                     
                    </div>
                
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th ><div className="text-white mb-0 text-center">Hole</div></th>
                    <th ><div className="text-white mb-0 text-center">Par</div></th>

                    <th >
                        <div className="text-white mb-0 text-center">
                           -10%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           -5%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
     
   
                    <th >
                        <div className="text-white mb-0 text-center">
                            Standard
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           +5%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           +10%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                 
                   
                  
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <th   >
                    
                    </th>
                    <td>
                   
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox-10" checked={check10m} class="" id="box1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox-10"/>
                      </div>           
                    </td>
                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox-5" checked={check5m} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox-5" />
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        <input key="checkbox 0" checked={check0} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox 0"/>
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox 5" checked={check5} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}}  onChange={handleChange} value="checkbox 5"/>
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox 10" checked={check10} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}}  onChange={handleChange} value="checkbox 10"/>
                      </div>     
                    </td>
              
                  </tr>
                  {
                    frontTimeList.length > 0 && isFrontEdit ?
                      frontTimeList.map((item, index) =>
                        index < 9 ?
                        
                        <tr>
                          <th>
                          <div className="text-white mb-0 text-center">{item.desc}</div>
                          </th>
                          <td>
                            <div style={{padding: "0px", width: "100px"}} className="text-white mb-0 text-center">
                                
                              <Input 
                                className="form-control-flush"
                                defaultValue={item.par}
                                type="select"
                                id={index}
                                onChange={(e) => onFrontSelect(e)}
                              >
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Input>
                            
                            </div>
                          </td>

                          <td>
                          <div className="text-white mb-0 text-center">
                            {
                                 Math.round((item.box_time * 0.9)* 100)/100
                            }
                            </div>
                          </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                            { 
                                 Math.round((item.box_time * 0.95)* 100)/100
                            }
                            </div>
                          </td>
                      
                          <td>
                              <div style={{padding: "0px", width: "100px"}} className="text-white mb-0 text-center">
                                  <Input 
                                    className="form-control-flush"
                                    id={index}
                                    value={item.box_time}
                                    onChange={(e) => onFrontChange(e)}
                                    type="text"
                                  />
                              </div>
                          </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                            {
                                 Math.round((item.box_time * 1.05)* 100)/100
                            }
                            </div>
                          </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                            { 
                                 Math.round((item.box_time * 1.1)* 100)/100
                            }
                            </div>
                          </td>
                        </tr>

                        :
                        <></>
                      )

                     

                      :
                      <></>
                  }

                  {
                    boxTimeList.length > 0 && !isFrontEdit ?
                      boxTimeList.map((item, index) => 
                        index < 9 ?
                          <tr key={index}>
                            <th >
                            <div className="text-white mb-0 text-center">  {item.desc} </div>
                            </th>
                            <td>
                            <div className="text-white mb-0 text-center"> {item.par} </div>
                            </td>

                            <td>
                            <div className="text-white mb-0 text-center">{
                               
                                Math.round((item.box_time * 0.9)* 100)/100
                              }
                              </div>
                            </td>
                            <td>
                            <div className="text-white mb-0 text-center">
                              {
                               
                                Math.round((item.box_time * 0.95)* 100)/100
                              }
                              </div>
                            </td>
                        
                            <td><div className="text-white mb-0 text-center">{item.box_time}</div></td>
                            <td>
                            <div className="text-white mb-0 text-center">
                              {
                               
                                Math.round((item.box_time * 1.05)* 100)/100
                              }
                              </div>
                            </td>
                            <td>
                            <div className="text-white mb-0 text-center">
                              {
                               
                                Math.round((item.box_time * 1.1)* 100)/100
                              }
                              </div>
                            </td>
                          </tr>
                          :
                          <></>
                        
                        
                      )
                      :
                      <></>
                  }

                      <tr>
                       <th >
                          <div className="text-white mb-0 text-center">
                          Sub Total
                          </div>
                        </th>
                        <td>
                          <div className="text-white mb-0 text-center">
                          {frontParTotal}
                          </div>
                        </td> 

                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((frontTimeTotal * 0.9)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((frontTimeTotal * 0.95)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {frontTimeTotal}
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((frontTimeTotal * 1.05)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((frontTimeTotal * 1.1)* 100)/100
                          }
                          </div>
                        </td>
                      </tr>
     

                  {
                    isFrontEdit && frontTimeList.length > 0?
                    !existTurn ?
                    <></>
                    :
                      <tr>
                        <th >
                        <div className="text-white mb-0 text-center">
                          Turn
                          </div>
                        </th>
                        <td>
                          
                        </td> 
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                          
                            Math.round((frontTimeList[9].box_time * 0.9)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                           Math.round((frontTimeList[9].box_time * 0.95)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                         
                          <div style={{padding: "0px", width: "100px"}}>
                          
                            <Input 
                              className="form-control-flush"
                              id={9}
                              value={frontTimeList[9].box_time}
                              onChange={(e) => onFrontChange(e)}
                              type="text"
                            />
                          </div>     
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                           Math.round((frontTimeList[9].box_time * 1.05)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                           Math.round((frontTimeList[9].box_time * 1.1)* 100)/100
                          }
                          </div>
                        </td>
                      </tr>
                      :
                      boxTimeList.length > 0 && !isFrontEdit ?
                          
                      !existTurn ?
                      <></>
                        :
                        <tr>
                          <th>
                          <div className="text-white mb-0 text-center">
                            Turn
                            </div>
                          </th>
                          <td>
                            
                          </td> 
                          <td>
                          <div className="text-white mb-0 text-center">
                            {
                            
                              Math.round((boxTimeList[9].box_time * 0.9)* 100)/100
                            }
                            </div>
                          </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                            {
                        
                            Math.round((boxTimeList[9].box_time * 0.95)* 100)/100
                            }
                            </div>
                          </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                            {
                            boxTimeList[9].box_time
                           
                            }
                            
                            </div>
                          </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                            {
                        
                              Math.round((boxTimeList[9].box_time * 1.05)* 100)/100
                            }
                            </div>
                          </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                            {
                       
                            Math.round((boxTimeList[9].box_time * 1.1)* 100)/100
                            }
                            </div>
                          </td>
                        </tr>

                        :
                        <></>
                  }
                </tbody>
              </Table>
            </Card>
          </Col>

          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                
                <Row className="align-items-center">
                    <div className="col">
                        <h3 className="text-info mb-0"></h3>
                    </div>

                    <div className="col">
                        <h2 className="text-white mb-0 text-center">Back 9</h2>
                    </div>


                  
                    <div className="col text-right">
                      {
                        isBackEdit ?
                          <div>
                            <Button
                              color="info"
                              id={"back"}
                              onClick={() => onBackSave()}
                              size="sm"
                            >
                            
                              Save
                            </Button>
                            <Button
                              color="warning"
                              id={"back"}
                              onClick={() => onBackCancel()}
                              size="sm"
                            >
                            
                              Cancel
                            </Button>
                          </div>
                          :
                          <Button
                            color="info"
                            id={"back"}
                            onClick={(e) => onEdit(e)}
                            size="sm"
                          >
                          
                            Edit
                          </Button>
                      }
                      </div>
                
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th ><div className="text-white mb-0 text-center">Hole </div></th>
                    <th ><div className="text-white mb-0 text-center">Par </div></th>

                    <th >
                        <div className="text-white mb-0 text-center">
                           -10%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           -5%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
     
   
                    <th >
                        <div className="text-white mb-0 text-center">
                            Standard
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           +5%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           +10%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                 
                   
                  
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <th   >
                    
                    </th>
                    <td>
                   
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox-10" checked={check10m} class="" id="box1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox-10"/>
                      </div>           
                    </td>
                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox-5" checked={check5m} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox-5" />
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        <input key="checkbox 0" checked={check0} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox 0"/>
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox 5" checked={check5} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}}  onChange={handleChange} value="checkbox 5"/>
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox 10" checked={check10} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}}  onChange={handleChange} value="checkbox 10"/>
                      </div>     
                    </td>
              
                  </tr>
                 
                  {
                    backTimeList.length > 0 && isBackEdit?
                      backTimeList.map((item, index) => 
                      
                      <tr>
                        <th >
                        <div className="text-white mb-0 text-center">
                          {item.desc}
                          </div>
                        </th>
                        <td>
                          <div style={{padding: "0px", width: "100px"}} className="text-white mb-0 text-center">
                              
                            <Input 
                              className="form-control-flush"
                              defaultValue={item.par}
                              type="select"
                              id={index}
                              onChange={(e) => onBackSelect(e)}
                            >
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          
                          </div>
                        </td>

                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                              Math.round((item.box_time * 0.9)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          { 
                              Math.round((item.box_time * 0.95)* 100)/100
                          }
                          </div>
                        </td>
                    
                        <td>
                            <div style={{padding: "0px", width: "100px"}} className="text-white mb-0 text-center">
                                <Input 
                                  className="form-control-flush"
                                  id={index}
                                  value={item.box_time}
                                  onChange={(e) => onBackChange(e)}
                                  type="text"
                                />
                            </div>
                        </td>
                          <td>
                          <div className="text-white mb-0 text-center">
                          {
                              Math.round((item.box_time * 1.05)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          { 
                              Math.round((item.box_time * 1.1)* 100)/100
                          }
                          </div>
                        </td>
                        
                      </tr>
                        
                        
                        
                      )
                      :
                      <></>
                  }
                  {
                    boxTimeList.length > 0 && !isBackEdit ?
                      boxTimeList.map((item, index) =>
                        index > 9 && index < 19 ?
                          <tr key={index}>
                            <th >
                            <div className="text-white mb-0 text-center">
                              {item.desc}
                              </div>
                            </th>
                            <td>
                            <div className="text-white mb-0 text-center">
                              {item.par}
                              </div>
                            </td>

                            <td>
                            <div className="text-white mb-0 text-center">
                              {
                               
                                Math.round((item.box_time * 0.9)* 100)/100
                              }
                              </div>
                            </td>
                            <td>
                            <div className="text-white mb-0 text-center">
                              {
                               
                                Math.round((item.box_time * 0.95)* 100)/100
                              }
                              </div>
                            </td>
                        
                            <td>
                            <div className="text-white mb-0 text-center">{item.box_time}
                            </div></td>
                            <td>
                            <div className="text-white mb-0 text-center">
                              {
                               
                                Math.round((item.box_time * 1.05)* 100)/100
                              }</div>
                            </td>
                            <td>
                            <div className="text-white mb-0 text-center">
                              {
                               
                                Math.round((item.box_time * 1.1)* 100)/100
                              }</div>
                            </td>
                          </tr>
                        :
                        <></>
                      )
                      :
                      <></>
                  }

                  <tr>
                       <th>
                       <div className="text-white mb-0 text-center">
                          Sub Total
                          </div>
                        </th>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {backParTotal}
                          </div>
                        </td> 
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((backTimeTotal * 0.9)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((backTimeTotal * 0.95)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {backTimeTotal}
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((backTimeTotal * 1.05)* 100)/100
                          }
                          </div>
                        </td>
                        <td>
                        <div className="text-white mb-0 text-center">
                          {
                            Math.round((backTimeTotal * 1.1)* 100)/100
                          }
                          </div>
                        </td>
                      </tr>
                </tbody>
              </Table>
            </Card>

            <Card className="bg-white shadow">
              <CardHeader className="bg-transparent border-0">
                
                <Row className="align-items-center">
                    <div className="col">
                        <h3 className="text-info mb-0"></h3>
                    </div>

                    <div className="col">
                        <h2 className="text-white mb-0 text-center">Summary</h2>
                    </div>


                  
                    <div className="col text-right">
                       

                      </div>
                
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th > <div className="text-white mb-0 text-center">Hole</div></th>
                    <th ><div className="text-white mb-0 text-center">Par </div></th>

                    <th >
                        <div className="text-white mb-0 text-center">
                           -10%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           -5%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
     
   
                    <th >
                        <div className="text-white mb-0 text-center">
                            Standard
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           +5%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                    <th >
                        <div className="text-white mb-0 text-center">
                           +10%
                        </div>
                        <div className="text-white mb-0 text-center">
                            Time
                        </div>
                    </th>
                 
                   
                  
                  </tr>
                </thead>
                <tbody>

                <tr>
                    <th   >
                    
                    </th>
                    <td>
                   
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox-10" checked={check10m} class="" id="box1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox-10"/>
                      </div>           
                    </td>
                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox-5" checked={check5m} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox-5" />
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        <input key="checkbox 0" checked={check0} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}} onChange={handleChange} value="checkbox 0"/>
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox 5" checked={check5} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}}  onChange={handleChange} value="checkbox 5"/>
                      </div>     
                    </td>

                    <td >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <input key="checkbox 10" checked={check10} class="" id="customCheck1" type="checkbox" style={{marginLeft: 0}}  onChange={handleChange} value="checkbox 10"/>
                      </div>     
                    </td>
              
                  </tr>

                  <tr>
                    <th   className="warning">
                    <div className="text-white mb-0 text-center">
                     Total
                     </div>
                    </th>
                    <td>
                    <div className="text-white mb-0 text-center">
                       {frontParTotal + backParTotal}
                       </div>
                    </td>

                    <td><div className="text-white mb-0 text-center">{Math.round((frontTimeTotal + backTimeTotal + turnTime) * 0.9 * 100) / 100} </div></td>
                    <td><div className="text-white mb-0 text-center">{Math.round((frontTimeTotal + backTimeTotal + turnTime) * 0.95 * 100) / 100} </div></td>
                 
                    <td><div className="text-white mb-0 text-center">{frontTimeTotal + backTimeTotal + turnTime} </div></td>
                    <td><div className="text-white mb-0 text-center">{Math.round((frontTimeTotal + backTimeTotal + turnTime) * 1.05 * 100) / 100} </div></td>
                    <td><div className="text-white mb-0 text-center">{Math.round((frontTimeTotal + backTimeTotal + turnTime) * 1.1 * 100) / 100} </div></td>
                  
                  </tr>

                  <tr>
                    <th  scope="row" className="warning">
                  
                    </th>
                    <td>
                     
                    </td>
                    <td><div className="text-white mb-0 text-center">{Math.floor((frontTimeTotal + backTimeTotal + turnTime) * 0.9 /60 )} hrs {Math.round(((frontTimeTotal + backTimeTotal + turnTime) * 0.9) % 60)} mins </div></td>
                    <td><div className="text-white mb-0 text-center">{Math.floor((frontTimeTotal + backTimeTotal + turnTime) * 0.95 /60 )} hrs {Math.round(((frontTimeTotal + backTimeTotal + turnTime) * 0.95) % 60)} mins </div></td>
                    <td><div className="text-white mb-0 text-center">{Math.floor((frontTimeTotal + backTimeTotal + turnTime)/60)} hrs {Math.floor((frontTimeTotal + backTimeTotal + turnTime)%60)} mins </div></td>
                    <td><div className="text-white mb-0 text-center">{Math.floor((frontTimeTotal + backTimeTotal + turnTime) * 1.05 /60 )} hrs {Math.round(((frontTimeTotal + backTimeTotal + turnTime) * 1.05) % 60)} mins </div></td>
                    <td><div className="text-white mb-0 text-center">{Math.floor((frontTimeTotal + backTimeTotal + turnTime) * 1.1 /60 )} hrs {Math.round(((frontTimeTotal + backTimeTotal + turnTime) * 1.1) % 60)} mins </div></td>
                  
                  </tr>

                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
     
      {/* </Container> */}
    </>
  );
};

export default SetTime;