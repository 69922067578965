import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col,
} from "reactstrap";

import helper from '../../services/helper.service';
import adminApi from '../../services/admin.service';
import session from '../../services/session.service';

function Logout(props) {

    const history = useHistory();

    useEffect(() => {
        session.clear();
        history.push('/');
        window.location.reload();

    })
   

    return (
        <></>
    )
}

export default Logout;