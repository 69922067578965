import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import PubNub from "pubnub";

import { PubNubProvider } from "pubnub-react";
import App from './route/app';


const pubnub = new PubNub({

  publishKey: 'pub-c-563a55cd-7693-4834-b8f4-eb280808eff8',

  subscribeKey: 'sub-c-5188beca-ecfc-4690-816f-3ed31210df76',
  autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
  restore: true, // enable catchup on missed messages

});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <PubNubProvider client={pubnub}>
      <App/>
    </PubNubProvider>
  </BrowserRouter>,
);

