import { useState, useEffect, useRef } from "react";
// node.js library that concatenates classes (strings)

// reactstrap components
import {
	Card,
	CardBody,
	CardHeader,
	Table,
	Row,
	Button,
	Modal
} from "reactstrap";

import { usePubNub } from 'pubnub-react';

import { useHistory } from 'react-router-dom';
import CustomerHeader from "../../components/Headers/CustomerHeader";
import adminApi from '../../services/admin.service';
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

import './summary.scss';
global.sortMode = "byTime";
let isBtnPress0 = true;

function  AdminSummary(props){
	const history = useHistory();

	let count = 0;

	const [isRead, setIsRead] = useState(false);
	const [golferList, setGolferList] = useState([]);

	const [golferCount, setGolferCount] = useState(0);

	const [btnText1, setBtnText1] = useState("");
	const [btnText2, setBtnText2] = useState("");
	const [btnText3, setBtnText3] = useState("");
	const [btnText4, setBtnText4] = useState("");
	const [btnText0, setBtnText0] = useState("");

	const [btnPress0, setBtnPress0] = useState(true);
	const [btnPress1, setBtnPress1] = useState(false);
	const [btnPress2, setBtnPress2] = useState(false);
	const [btnPress3, setBtnPress3] = useState(false);
	const [btnPress4, setBtnPress4] = useState(false);

	const [prevDay, setPrevDay] = useState(0);

	const [alertShow, setAlertShow] = useState(false);
	const pubnub = usePubNub();

	const onGetToday = () => {
		getGolferList();
		setBtnPress0(true);
		isBtnPress0 = true;
		setBtnPress1(false);
		setBtnPress2(false);
		setBtnPress3(false);
		setBtnPress4(false);
		getPrevGolferList(0);
		setPrevDay(0);
	}

	const onGetPrevDay1 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(true);
		setBtnPress2(false);
		setBtnPress3(false);
		setBtnPress4(false);
		getPrevGolferList(1);
		setPrevDay(1);
	}

	const onGetPrevDay2 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(false);
		setBtnPress2(true);
		setBtnPress3(false);
		setBtnPress4(false);
		getPrevGolferList(2);
		setPrevDay(2);
	}

	const onGetPrevDay3 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(false);
		setBtnPress2(false);
		setBtnPress3(true);
		setBtnPress4(false);
		getPrevGolferList(3);
		setPrevDay(3);
	}

	const onGetPrevDay4 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(false);
		setBtnPress2(false);
		setBtnPress3(false);
		setBtnPress4(true);
		getPrevGolferList(4);
		setPrevDay(4);
	}

	const getPrevGolferList = (prevDay) => {
		console.log("prev day: ", prevDay);

		adminApi.getPrevGolferList({prev_day: prevDay })
			.then((response) => {
				console.log("getGolferList Response: ");
				console.log(response);

				if(response.status === 200){

				let tempGolferList = response.data;
				global.golferList = tempGolferList;
			
				// setGolferCount(tempGolferList.length);	
				processGolferList();
				} else {
					helper.showToast("Error", "Failed to get Hole Time List", 3);
				}
			})
			.catch((error) => {
					console.log("err: ", error);
					helper.showToast("Error", "Invaid auth", 3);
			})
	}

	useEffect(() => {
			
		// console.log("clubId: ", clubId);
		// console.log("ClubName: ", clubName);

		if(!isRead){
			setBtnText0(getPrevDay(0));
			setBtnText1(getPrevDay(1));
			setBtnText2(getPrevDay(2));
			setBtnText3(getPrevDay(3));
			setBtnText4(getPrevDay(4));
			setIsRead(true);
			getGolferList();
		}

	});

	useEffect(() => {
		const interval = setInterval(() => {
			// processGolferList();
			count ++;
			if(count == 30){
				count = 0;
				if(isBtnPress0){
					getGolferList();
				}
				
			}
			
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const processGolferList = () => {


		// console.log("new golferList: ", global.golferList);
		// console.log("golferList: ", global.oldGolferList);

		let tempArray = [];
		for(let i = 0; i < global.golferList.length; i++){

			let tempItem = global.golferList[i];

			if(tempItem.club != null){
				let item = {
					golferId: tempItem.golfer_id,
					visitTime: tempItem.visit_time,
					elapsedTime: tempItem.elapsed_time,
					leftElapsedTime: tempItem.left_elapsed_time,
					rightElapsedTime: tempItem.right_elapsed_time,
					phoneType: tempItem.opt1,
					version: tempItem.version,
					finished: tempItem.finished,
					clubName: tempItem.club
				  }
				  tempArray.push(item);
			}
			
		}

		// console.log("tempArray:", tempArray);
		setGolferList(tempArray);
		setGolferCount(tempArray.length);
		global.golferList = tempArray;
	}

	const getGolferList = () => {


		adminApi.getPrevGolferList({prev_day: 0})
			.then((response) => {
				console.log("getGolferddList Response: ");
				console.log(response);

				if(response.status === 200){

				let tempGolferList = response.data;
				global.golferList = tempGolferList;
				processGolferList();

			
				// setGolferCount(tempGolferList.length);
		
				} else {
					helper.showToast("Error", "Failed to get Hole Time List", 3);
				}
			})
			.catch((error) => {
					console.log("err: ", error);
					helper.showToast("Error", "Invaid auth", 3);
			})
	}

	const getPrevDay = (diff) =>{
		const options = { month: 'numeric', day: 'numeric' };
		const date = new Date();
		console.log("local time: ", date);

		var d = new Date();
		d.setDate(d.getDate() - diff);
		return d.toLocaleDateString('en-US', options);
	}

	const sortByTime = (newArray) => {
		console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.visitTime.toUpperCase(); // ignore upper and lowercase
			const nameB = b.visitTime.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByTimeDecrease = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.visitTime.toUpperCase(); // ignore upper and lowercase
			const nameB = b.visitTime.toUpperCase(); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByTime = () => {
		console.log("sort Mode: ", global.sortMode);
		if(global.sortMode == "byTime"){
			global.sortMode = "byTimeDecrease";
			sortByTimeDecrease(golferList);
		} else {
			global.sortMode = "byTime";
			sortByTime(golferList);
		}
	}

	const sortByClub = (newArray) => {
		console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.clubName.toUpperCase(); // ignore upper and lowercase
			const nameB = b.clubName.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByClubDecrease = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.clubName.toUpperCase(); // ignore upper and lowercase
			const nameB = b.clubName.toUpperCase(); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByClub = () => {
		console.log("sort Mode: ", global.sortMode);
		if(global.sortMode == "byClub"){
			global.sortMode = "byClubDecrease";
			sortByClubDecrease(golferList);
		} else {
			global.sortMode = "byClub";
			sortByClub(golferList);
		}
	}

	const sortByTotal = (newArray) => {
		console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.elapsedTime; // ignore upper and lowercase
			const nameB = b.elapsedTime; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByTotalDecrease = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.elapsedTime; // ignore upper and lowercase
			const nameB = b.elapsedTime; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByTotal = () => {
		console.log("sort Mode: ", global.sortMode);
		if(global.sortMode == "byTotal"){
			global.sortMode = "byTotalDecrease";
			sortByTotalDecrease(golferList);
		} else {
			global.sortMode = "byTotal";
			sortByTotal(golferList);
		}
	}

	const sortByLeft = (newArray) => {
		console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.leftElapsedTime; // ignore upper and lowercase
			const nameB = b.leftElapsedTime; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByLeftDecrease = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.leftElapsedTime; // ignore upper and lowercase
			const nameB = b.leftElapsedTime; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByLeft = () => {
		console.log("sort Mode: ", global.sortMode);
		if(global.sortMode == "byLeft"){
			global.sortMode = "byLeftDecrease";
			sortByLeftDecrease(golferList);
		} else {
			global.sortMode = "byLeft";
			sortByLeft(golferList);
		}
	}

	const sortByRight = (newArray) => {
		console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.rightElapsedTime; // ignore upper and lowercase
			const nameB = b.rightElapsedTime; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByRightDecrease = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.rightElapsedTime; // ignore upper and lowercase
			const nameB = b.rightElapsedTime; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByRight = () => {
		console.log("sort Mode: ", global.sortMode);
		if(global.sortMode == "byRight"){
			global.sortMode = "byRightDecrease";
			sortByRightDecrease(golferList);
		} else {
			global.sortMode = "byRight";
			sortByRight(golferList);
		}
	}

	const sortByActive = (newArray) => {
		console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.finished; // ignore upper and lowercase
			const nameB = b.finished; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByActiveDecrease = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.finished; // ignore upper and lowercase
			const nameB = b.finished; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByActive = () => {
		if(isBtnPress0){
			console.log("sort Mode: ", global.sortMode);
			if(global.sortMode == "byActive"){
				global.sortMode = "byActiveDecrease";
				sortByActiveDecrease(golferList);
			} else {
				global.sortMode = "byActive";
				sortByActive(golferList);
			}
		}
		
	}

	return (
		<>
			<CustomerHeader />
			<Card className="bg-white shadow mt--6 mb-5">

				<CardHeader className="bg-transparent border-0">
						
					<Row className="align-items-center">
						<div className="col">
							<h3 className="text-info mb-0 small">Total Users: {golferCount}</h3>
						</div>
						<div className="col">
							<h2 className="text-white mb-0 text-center small">Active Golfers</h2>
						</div>
						<div className="col text-right">
							<Button
								color= {btnPress4 ?"warning" : "info"}
								onClick={() =>onGetPrevDay4()}
								size="sm"
								>
								
								{btnText4}
							</Button>
							<Button
								color= {btnPress3 ?"warning" : "info"}
								onClick={() =>onGetPrevDay3()}
								size="sm"
								>
								
								{btnText3}
							</Button>
							<Button
								color= {btnPress2 ?"warning" : "info"}
								onClick={() =>onGetPrevDay2()}
								size="sm"
								>
								
								{btnText2}
							</Button>
							<Button
								color= {btnPress1 ?"warning" : "info"}
								onClick={() =>onGetPrevDay1()}
								size="sm"
								>
								
								{btnText1}
							</Button>
							<Button
								color= {btnPress0 ?"warning" : "info"}
								onClick={() =>onGetToday()}
								size="sm"
								>
								
								{btnText0}
							</Button>
						</div>
					</Row>

				</CardHeader>

				<CardBody>
					<Table
						id="table-dashboard-user"
						className={`table-gray`}
						responsive
					>

						<table className="table table-bordered table-hover text-center" style={{ borderColor: "black" }}>
							<thead style={{ color: "black", borderColor: "black", fontSize: 18 }}>
							
								<tr>
								<th
									// onClick={() => onSortByTime()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									NO
									{/* <i className="fas fa-sort"></i> */}
									</div>
								</th>
								<th
									onClick={() => onSortByClub()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Club Name<i className="fas fa-sort"></i>
									</div>
								</th>
								{/* <th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}>Device</th> */}

								<th
									// onClick={() => onSortByTime()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Golfer Id
									{/* <i className="fas fa-sort"></i> */}
									</div>
								</th>
								<th
									// onClick={() => onSortByTime()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Phone Type
									{/* <i className="fas fa-sort"></i> */}
									</div>
								</th>
								<th
									// onClick={() => onSortByTime()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Version
									{/* <i className="fas fa-sort"></i> */}
									</div>
								</th>
								<th
									onClick={() => onSortByTime()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Visit<i className="fas fa-sort"></i>
									</div>
								</th>
								<th
									onClick={() => onSortByTotal()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Total<i className="fas fa-sort"></i>
									</div>
								</th>
								<th
									onClick={() => onSortByLeft()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Left<i className="fas fa-sort"></i>
									</div>
								</th>
								<th
									onClick={() => onSortByRight()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Right<i className="fas fa-sort"></i>
									</div>
								</th>
								<th
									onClick={() => onSortByActive()}
									style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
								>
									<div className="text-black mb-0 text-center">
									Active<i className="fas fa-sort"></i>
									</div>
								</th>
							
								</tr>
							</thead>
							<tbody style={{ color: "black" }}>
								{
								golferList.length > 0 ?
									golferList.map((item, index) =>
									<tr key={index}>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{index + 1}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.clubName}</td>
										{/* <td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.deviceType}</td> */}
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.golferId}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.phoneType}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.version}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.visitTime}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.elapsedTime}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.leftElapsedTime}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.rightElapsedTime}</td>
										<td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.finished == "true" ? "" : "Active"}</td>	
									</tr>
									)
									:
									<></>
								}


							</tbody>

						</table>


					</Table>
				</CardBody>
		</Card>
	</>
	);
};

export default AdminSummary;