import http from "./http.service";
import session from "./session.service";

const path = {
    login: 'admin/loginClub',
    addClub: 'admin/addClub',
    getClubList: 'admin/getClubList',
    removeClub: 'admin/removeClub',
    updateClub: 'admin/updateClub',
    getPrevGolferList: 'admin/getPrevGolferList',
    updateBox: 'admin/updateBox',
    getClubHistory: 'admin/getClubHistory',
    getHistory: 'admin/getHistory',
    updateBoxWithCSV: 'admin/updateBoxWithCSV',
}

export default {
    login: function(credential) {
        return http.post(path.login, credential, {});
    },
    addClub: function(body) {
        return http.postClub(path.addClub, body, {});
    },
    getClubList: function(body){
        return http.post(path.getClubList, body, {});
    },
    deleteClub: function(body){
        return http.post(path.removeClub, body, {});
    },
    updateClub: function(body){
        return http.updateClub(path.updateClub, body, {});
    },
    getPrevGolferList: function(body){
        return http.post(path.getPrevGolferList, body, {});
    },
    updateBox: function(body){
        return http.post(path.updateBox, body, {});
    },
    getClubHistory: function(body){
        return http.post(path.getClubHistory, body, {});
    },
    getHistory: function(body){
        return http.post(path.getHistory, body, {});
    },
    updateBoxWithCSV: function(body){
        return http.post(path.updateBoxWithCSV, body, {});
    }
}