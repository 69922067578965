import axios from "axios";
import environments from "../environments";

export default {
    post: function(path, body, headers = {}) {
        return fetch(`${environments.baseApi}/${path}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                ...headers
            },
            //make sure to serialize your JSON body
            body: JSON.stringify(body)
        })
        .then((response) => response.json());
    },
    postClub: function(path, body, headers = {}) {

        let data = new FormData();
        data.append('name', body.name)
        data.append('email', body.email)
        data.append('username', body.username)
        data.append('password', body.password)
        data.append('latitude', body.latitude)
        data.append('longitude', body.longitude)
        data.append('radius', body.radius)
        data.append('timezone', body.timezone)
        data.append('turn', body.turn)
        data.append('heading', body.heading)
        data.append('thumbnail', body.thumbnail)

        data.append('lati_center', body.lati_center)
        data.append('long_center', body.long_center)
        data.append('radius_center', body.radius_center)
        data.append('map', body.map)

        return axios.post(`${environments.baseApi}/${path}`,data, {
    
            headers: {     
                'Accept': 'application/json',        
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                ...headers
            }
        });
       
    },
    updateClub: function(path, body, headers = {}) {

        let data = new FormData();
        data.append('club_id', body.club_id);
        data.append('name', body.name);
        data.append('email', body.email);
        data.append('username', body.username);
        data.append('password', body.password);
        data.append('latitude', body.latitude);
        data.append('longitude', body.longitude);
        data.append('radius', body.radius);
        data.append('timezone', body.timezone)
        data.append('thumbnail', body.thumbnail);
        data.append('turn', body.turn);
        data.append('heading', body.heading);

        data.append('lati_center', body.lati_center)
        data.append('long_center', body.long_center)
        data.append('radius_center', body.radius_center)
        data.append('map', body.map)


        return axios.post(`${environments.baseApi}/${path}`,data, {
    
            headers: {     
                'Accept': 'application/json',        
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                ...headers
            }
        });
       
    },
    get: function(path, headers = {}) {
        return fetch(`${environments.baseApi}/${path}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                ...headers
            }
        })
        .then((response) => response.json());
    },
}