import { useState, useEffect, useRef } from "react";
// node.js library that concatenates classes (strings)

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Button,
  Modal
} from "reactstrap";

import { usePubNub } from 'pubnub-react';

import { useHistory } from 'react-router-dom';
import CustomerHeader from "../../components/Headers/CustomerHeader";
import BounceLoader from "react-spinners/BounceLoader";
import adminApi from '../../services/admin.service';
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

import { CSVLink } from "react-csv";

global.sortMode = "byTime";
let isBtnPress0 = true;

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const headers = [

  { label: "Club Id", key: "clubId" },
  { label: "Name", key: "name" },
  { label: "Last Use", key: "lastUse" },
  { label: "Golfer Count", key: "golferCount" },
  { label: "Total Visits", key: "totalCount" },
  { label: "Total Minutes", key: "totalSum" },
  { label: "Left Visits", key: "leftCount" },
  { label: "Left Minutes", key: "leftSum" },
  { label: "Right Visits", key: "rightCount" },
  { label: "Right Minutes", key: "rightSum" }
];



function Dashboard(props) {

  const history = useHistory();

  const [clubCount, setClubCount] = useState(0);
  const [historyList, setHistoryList] = useState([]);
  const [totalItem, setTotalItem] = useState();
  let [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    period: "30"
  });

  const clubId = "";
  const clubName = "";
  const clubNameToShow = "";
  const clubUserName = "";

  useEffect(() => {

    console.log("clubId: ", clubId);
    console.log("ClubName: ", clubName);
    console.log("clubNameToShow: ", clubNameToShow);
    console.log("clubUserName: ", clubUserName);

    if (global.clubHistory) {
      setClubCount(global.clubHistory.length);

      historyProcess(global.clubHistory);
    } else {
      getClubHistory("30");
    }

  }, []);

  const getClubHistory = (period) => {
    setLoading(true);
    adminApi.getClubHistory({ club: clubName, period: period })
      .then((response) => {
        console.log("getHistory Response: ");
        console.log(response);

        setLoading(false);
        global.sortNameMode = "byUpdated";
        if (response.status === 200) {
          global.clubHistory = response.data;

          setClubCount(global.clubHistory.length);

          historyProcess(global.clubHistory);

        } else {
          helper.showToast("Error", "Failed to get Hole Time List", 3);
        }
      })
      .catch((error) => {
        console.log("err: ", error);
        helper.showToast("Error", "Invaid auth", 3);
      })
  }

  const historyProcess = (historyList) => {
    let totalCount = 0;
    let totalSum = 0;
    let totalLeftCount = 0;
    let totalLeftSum = 0;
    let totalRightCount = 0;
    let totalRightSum = 0;
    let totalGolferCount = 0;

    let tempArray = [];
    for (let i = 0; i < historyList.length; i++) {
      let tempItem = historyList[i];

      let item = {
        clubId: tempItem.club_id,
        totalCount: tempItem.total_count,
        totalSum: tempItem.total_sum,
        leftCount: tempItem.left_count,
        leftSum: tempItem.left_sum,
        rightCount: tempItem.right_count,
        rightSum: tempItem.right_sum,
        lastUse: tempItem.last_use,
        name: tempItem.name_show,
        clubName: tempItem.name,
        golferCount: tempItem.golfer_count
      }
      tempArray.push(item);

      totalCount = totalCount + Number(item.totalCount);
      totalSum = totalSum + Number(item.totalSum);
      totalLeftCount = totalLeftCount + Number(item.leftCount);
      totalLeftSum = totalLeftSum + Number(item.leftSum);
      totalRightCount = totalRightCount + Number(item.rightCount);
      totalRightSum = totalRightSum + Number(item.rightSum);
      totalGolferCount = totalGolferCount + Number(item.golferCount);
    }
    setHistoryList(tempArray);

    let totalItem = {
      totalCount: totalCount,
      totalSum: totalSum,
      leftCount: totalLeftCount,
      leftSum: totalLeftSum,
      rightCount: totalRightCount,
      rightSum: totalRightSum,
      totalGolferCount: totalGolferCount
    }

    setTotalItem(totalItem);
  }

  const handleClickPeriodFilter = (period) => {
    setFilter({ ...filter, period: period });
    // getReportRound(global.golfer.golfer_id, period);
    getClubHistory(period);
  }

  const onSortByName = () => {
    if (global.sortNameMode == "byName") {
      global.sortNameMode = "byNameDecrease";
      sortByNameDecrease(historyList);
    } else {
      global.sortNameMode = "byName";
      sortByName(historyList);
    }

  }

  const sortByName = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = a.name; // ignore upper and lowercase
      const nameB = b.name; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByNameDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = a.name; // ignore upper and lowercase
      const nameB = b.name; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByLastUse = () => {
    if (global.sortNameMode == "sortByLastUse") {
      global.sortNameMode = "sortByLastUseDecrease";
      sortByLastUseDecrease(historyList);
    } else {
      global.sortNameMode = "sortByLastUse";
      sortByLastUse(historyList);
    }

  }

  const sortByLastUse = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = a.lastUse; // ignore upper and lowercase
      const nameB = b.lastUse; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByLastUseDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = a.lastUse; // ignore upper and lowercase
      const nameB = b.lastUse; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByVisit = () => {
    if (global.sortNameMode == "sortByVisit") {
      global.sortNameMode = "sortByVisitDecrease";
      sortByVisitDecrease(historyList);
    } else {
      global.sortNameMode = "sortByVisit";
      sortByVisit(historyList);
    }

  }

  const sortByVisit = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.totalCount); // ignore upper and lowercase
      const nameB = Number(b.totalCount); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByVisitDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.totalCount); // ignore upper and lowercase
      const nameB = Number(b.totalCount); // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByMinute = () => {
    if (global.sortNameMode == "sortByMinute") {
      global.sortNameMode = "sortByMinuteDecrease";
      sortByMinuteDecrease(historyList);
    } else {
      global.sortNameMode = "sortByMinute";
      sortByMinute(historyList);
    }

  }

  const sortByMinute = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.totalSum); // ignore upper and lowercase
      const nameB = Number(b.totalSum); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByMinuteDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.totalSum); // ignore upper and lowercase
      const nameB = Number(b.totalSum); // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByLeftVisit = () => {
    if (global.sortNameMode == "sortByLeftVisit") {
      global.sortNameMode = "sortByLeftVisitDecrease";
      sortByLeftVisitDecrease(historyList);
    } else {
      global.sortNameMode = "sortByLeftVisit";
      sortByLeftVisit(historyList);
    }

  }

  const sortByLeftVisit = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.leftCount); // ignore upper and lowercase
      const nameB = Number(b.leftCount); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByLeftVisitDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.leftCount); // ignore upper and lowercase
      const nameB = Number(b.leftCount); // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByLeftMinute = () => {
    if (global.sortNameMode == "sortByLeftMinute") {
      global.sortNameMode = "sortByLeftMinuteDecrease";
      sortByLeftMinuteDecrease(historyList);
    } else {
      global.sortNameMode = "sortByLeftMinute";
      sortByLeftMinute(historyList);
    }

  }

  const sortByLeftMinute = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.leftSum); // ignore upper and lowercase
      const nameB = Number(b.leftSum); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByLeftMinuteDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.leftSum); // ignore upper and lowercase
      const nameB = Number(b.leftSum); // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByRightVisit = () => {
    if (global.sortNameMode == "sortByRightVisit") {
      global.sortNameMode = "sortByLeftRightDecrease";
      sortByLeftRightDecrease(historyList);
    } else {
      global.sortNameMode = "sortByRightVisit";
      sortByRightVisit(historyList);
    }

  }

  const sortByRightVisit = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.rightCount); // ignore upper and lowercase
      const nameB = Number(b.rightCount); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByLeftRightDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.rightCount); // ignore upper and lowercase
      const nameB = Number(b.rightCount); // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByRightMinute = () => {
    if (global.sortNameMode == "sortByRightMinute") {
      global.sortNameMode = "sortByRightMinuteDecrease";
      sortByRightMinuteDecrease(historyList);
    } else {
      global.sortNameMode = "sortByRightMinute";
      sortByRightMinute(historyList);
    }

  }

  const sortByRightMinute = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.rightSum); // ignore upper and lowercase
      const nameB = Number(b.rightSum); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByRightMinuteDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = Number(a.rightSum); // ignore upper and lowercase
      const nameB = Number(b.rightSum); // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onSortByGolferCount = () => {
    if (global.sortNameMode == "sortByGolferCount") {
      global.sortNameMode = "sortByGolferCountDecrease";
      sortByGolferCountDecrease(historyList);
    } else {
      global.sortNameMode = "sortByGolferCount";
      sortByGolferCount(historyList);
    }

  }

  const sortByGolferCount = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = a.golferCount; // ignore upper and lowercase
      const nameB = b.golferCount; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const sortByGolferCountDecrease = (newArray) => {
    const tempArray = [...newArray].sort((a, b) => {
      const nameA = a.golferCount; // ignore upper and lowercase
      const nameB = b.golferCount; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setHistoryList(tempArray);
  }

  const onDetail = (e) => {
    const index = e.target.id;
    console.log("detail index: ", index);

    const selectedClub = historyList[index];
    console.log("selected selectedClub; ", selectedClub);
    history.push('/admin/dashboard', { club: selectedClub });
  }

  return (
    <>

      <CustomerHeader />
      <Card className="bg-white shadow mt--6 mb-5">

        <CardHeader className="bg-transparent border-0">
          <BounceLoader color="#36d7b7" loading={loading} cssOverride={override} size={40} />
          <Row className="align-items-center">
            <div className="col">
              <h3 className="text-info mb-0 small">Total Users: {clubCount}</h3>
            </div>
            <div className="col">
              <h2 className="text-black mb-0 text-center ">{clubNameToShow}</h2>
            </div>
            <div className="col text-right">
              <Button
                color={filter.period === "30" ? "success" : "info"}

                onClick={(e) => handleClickPeriodFilter("30")}

                size="sm"
              // id={index}
              >

                30 Days
              </Button>
              <Button
                color={filter.period === "90" ? "success" : "info"}

                onClick={(e) => handleClickPeriodFilter("90")}

                size="sm"
              // id={index}
              >

                90 Days
              </Button>
              <Button
                color={filter.period === "365" ? "success" : "info"}

                onClick={(e) => handleClickPeriodFilter("365")}

                size="sm"
              // id={index}
              >

                Cal Year
              </Button>
              <Button
                size="sm"
                color="white"
              // onClick={(e) => exportCSV()}
              >
                <CSVLink data={historyList} headers={headers}>
                  Export CSV
                </CSVLink>
              </Button>

            </div>

          </Row>

        </CardHeader>
        <CardBody>
          <Table
            id="table-dashboard-user"
            className={`table-gray`}
            responsive
          >

            <table className="table table-bordered table-hover text-center" style={{ borderColor: "black" }}>
              <thead style={{ color: "black", borderColor: "black", fontSize: 18 }}>
                <tr>
                  {/* <th style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}></th> */}
                  <th style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }} colSpan={11}>Past {filter.period} Days</th>
                </tr>
                <tr style={{ fontSize: 14, borderColor: "black", fontWeight: "normal", borderLeftWidth: 1 }}>
                  <th style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }} colSpan={2}> Club</th>

                  <th style={{ fontSize: 14, borderColor: "black", fontWeight: "bold", borderLeftWidth: 1 }} colSpan={3}>Total</th>
                  <th style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }} colSpan={2}>Left Switch</th>
                  <th style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }} colSpan={2}>Right Switch</th>
                </tr>
                <tr>
                  <th
                    onClick={() => onSortByName()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Club Name<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  {/* <th style={{fontSize: 14, borderColor: "black", fontWeight: "bold"}}>Device</th> */}

                  <th
                    onClick={() => onSortByLastUse()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Last Use<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByGolferCount()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Golfer Count<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByVisit()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Visits<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByMinute()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Minutes<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByLeftVisit()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Visits<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByLeftMinute()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Minutes<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByRightVisit()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Visits<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByRightMinute()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Minutes<i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th
                    onClick={() => onSortByRightMinute()}
                    style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }}
                  >
                    <div className="text-black mb-0 text-center">
                      Action
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style={{ color: "black" }}>
                {
                  historyList.length > 0 ?
                    historyList.map((item, index) =>
                      <tr key={index}>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.name}</td>
                        {/* <td style={{fontSize: 14, borderColor: "black", fontWeight: "normal"}}>{item.deviceType}</td> */}
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.lastUse}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.golferCount}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.totalCount}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.totalSum}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.leftCount}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.leftSum}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.rightCount}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{item.rightSum}</td>
                        <td style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>
                          <Button
                            color="success"
                            size="sm"
                            id={index}
                            onClick={(e) => onDetail(e)}
                          >
                            Detail
                          </Button>
                        </td>
                      </tr>
                    )
                    :
                    <></>
                }


              </tbody>
              <tfoot style={{ color: "black" }}>
                {
                  totalItem ?
                    <tr>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "bold" }} colSpan={2}>Grand Total</th>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{totalItem.totalGolferCount}</th>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{totalItem.totalCount}</th>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{totalItem.totalSum}</th>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{totalItem.leftCount}</th>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{totalItem.leftSum}</th>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{totalItem.rightCount}</th>
                      <th style={{ fontSize: 14, borderColor: "black", fontWeight: "normal" }}>{totalItem.rightSum}</th>
                    </tr>
                    :
                    <></>

                }

              </tfoot>
            </table>


          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default Dashboard;