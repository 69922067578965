import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import AddClub from "../views/AddClub";
import EditClub from "../views/EditClub";
import EditBox from "../views/EditBox";
import DisplayStatus from "../views/DisplayStatus";
import Summary from "../views/Summary";
import SetTime from "../views/SetTime";
import SetName from "../views/SetName";
import Logout from "../views/Logout";
import HistoryAnalysis from "../views/HistoryAnalysis";
import HistoryList from "../views/HistoryList";
import AdminSummary from "../views/AdminSummary";
import DisplayMap from "../views/DisplayMap";
import IndividualHistory from "../views/IndividualHistory";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/activegolfers",
    name: "Active Golfers",
    icon: "ni ni-tv-2 text-primary",
    component: AdminSummary,
    layout: "/admin",
  },

  {
    path: "/dashboard",
    name: "Club Dashboard",
    icon: "ni ni-chart-pie-35 text-primary",
    component: Summary,
    layout: "/admin",
  },

  {
    path: "/clubstatus",
    name: "Golf Status",
    icon: "ni ni-chart-pie-35 text-primary",
    component: IndividualHistory,
    layout: "/admin",
  },

  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-chart-pie-35 text-primary",
    component: Logout,
    layout: "/admin",
  },

  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Summary,
    layout: "/customer",
  },

  {
    path: "/setname",
    name: "Set Name",
    icon: "ni ni-tv-2 text-primary",
    component: SetName,
    layout: "/customer",
  },

  {
    path: "/individualhistory",
    name: "Golfer History",
    icon: "ni ni-tv-2 text-primary",
    component: IndividualHistory,
    layout: "/customer",
  },

  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-chart-pie-35 text-primary",
    component: Logout,
    layout: "/customer",
  },

];
export default routes;
